<template>
    <topBanner/>
    <aboutWestManagement/>
    <wasteManagementFeature/>
    <wasteManagementCategory/>
</template>

<script>
import topBanner from '../components/common/topBanner.vue'
import aboutWestManagement from '../components/waste management/aboutWestManagement.vue'
import wasteManagementFeature from '../components/waste management/wastManagementFeature.vue'
import wasteManagementCategory from '../components/waste management/wasteManagementCategory.vue'
export default {
    components: {
        topBanner,
        aboutWestManagement,
        wasteManagementFeature,
        wasteManagementCategory
    },
    data(){
        return{}
    }
}
</script>