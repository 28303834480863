<template>
  <div
    class="cmt-row action-row-section cmt-bgcolor-skincolor cmt-bg cmt-bgimage-yes bg-img3 clearfix"
  >
    <div class="cmt-row-wrapper-bg-layer cmt-bg-layer"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center">
            <div
              class="featured-icon-box icon-align-top-content style4 text-center"
            >
              <div class="featured-icon">
                <div
                  class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-white cmt-icon_element-size-lg"
                >
                  <i class="flaticon-call"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-desc">
                  <p>{{ wasteManagementData.title }}</p>
                </div>
                <div class="featured-title mt-15">
                  <h4>{{ wasteManagementData.desc }}</h4>
                </div>
              </div>
            </div>
            <router-link
              class="cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-border cmt-btn-color-white mt-15"
              to="/contact"
              title=""
              >Get In Touch</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from '../../Data/data.json'
export default {
    data(){
        return{
          wasteManagementData: ""
        }
    },
    created(){
      this.wasteManagementData = data.wastemanagement_feature
    }
}
</script>
