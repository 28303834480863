<template>
    <div class="container mt-60 mb-30">
      <div class="section-title title-style-center_text">
        <div class="title-header">
          <h5>{{ wasteManagementData.subtitle }}</h5>
          <h2 class="title">{{ wasteManagementData.title }}</h2>
        </div>
        <div class="heading-seperator"><span></span></div>
      </div>
      <p class="text-center mt-30" v-for="item in wasteManagementData.desc" :key="item.id">
        {{ item.row }}
      </p>
      <div class="row mt-60">
        <div class="col-md-4 col-sm-12">
          <div
            class="featured-icon-box icon-align-before-content icon-ver_align-top mb-30" 
            v-for="item in wasteManagementData.left_services" :key="item.id"
          >
            <div class="featured-content text-right pr-20">
              <div class="featured-title">
                <h5>{{ item.title }}</h5>
              </div>
              <div class="featured-desc">
                <p>
                  {{ item.row }}
                </p>
              </div>
            </div>
            <div class="featured-icon">
              <div
                class="cmt-icon cmt-icon_element-fill cmt-icon_element-color-skincolor cmt-icon_element-size-sm cmt-icon_element-style-rounded"
              >
                <i class="flaticon-ship-1"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 mb-40">
            <div class="imagewrapper">
                <img :src="getItem(wasteManagementData.image)" class="img-fluid" alt="marineImage" />
            </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="featured-icon-box icon-align-before-content icon-ver_align-top mb-30" v-for="item in wasteManagementData.right_services" :key="item.id">
            <div class="featured-icon">
              <div
                class="cmt-icon cmt-icon_element-fill cmt-icon_element-color-skincolor cmt-icon_element-size-sm cmt-icon_element-style-rounded"
              >
                <i class="flaticon-ship-1"></i>
              </div>
            </div>
            <div class="featured-content">
              <div class="featured-title">
                <h5>{{ item.title }}</h5>
              </div>
              <div class="featured-desc">
                <p>
                  {{ item.row }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import data from '../../Data/data.json'
export default {
    data(){
        return{
          wasteManagementData: ""
        }
    },
    created() {
      this.wasteManagementData = data.wastmanagement_services
    },
    methods: {
      getItem(pic) {
        if(pic) {
          return require("../../assets/images/" + pic)
        } else {
          return null
        }
      }
    }
}
</script>